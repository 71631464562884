@import "../../../../Pages/Analytics/font-variables";

//
// _menu.scss
//

// .font-primary {
//   font-family:  $font-family-primary;
// }

// .font-secondary {
//   font-family: $font-family-secondary;
// }

// .simple-text {
//   font-size: $font-size-simple-text;
//   line-height: $font-height-simple-text;
// }

.metismenu {
  margin: 0;

  li {
    display: block;
    width: 100%;
  }

  .mm-collapse {
    display: none;

    &:not(.mm-show) {
      display: none;
    }

    &.mm-show {
      display: block;
    }
  }

  .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
  }
}

.custom-inner-tooltip {
  background-color: $sidebar-menu-item-icon-color !important;
  font-family: $font-family-primary !important;
  opacity: 100% !important;
}
.custom-tooltip {
  border-radius: 20px !important;
}

.vertical-menu {
  width: $sidebar-width;
  z-index: 1001;
  background: $sidebar-bg !important;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: $header-height;
  box-shadow: none;
}

#page-topbar {
  .app-title {
    color: $header-app-title-color;
    font: normal 600 30.4px/140% $font-family-primary !important;
  }
}

.main-content {
  margin-left: $sidebar-width;
  overflow: hidden;

  .content {
    padding: 0 15px 10px 15px;
    margin-top: $header-height;
  }

  #layout-wrapper {
    > div {
      .cols-container {
        background-color: $sidebar-menu-item-active-bg !important;

        > .inbox-menu {
          padding: 0 12px 0 14px;

          // .col-xl-10 {
          //   padding: 0;
          //   width: fit-content;
          // }
          .compose-mail-button {
            // width: 190px;
            border: none;
            border-radius: 16.205px;
            color: $compose-mail-button-color;
            background-color: $compose-mail-button-background;
            padding: 2px 9px;
            box-shadow: 0px 16.20517px 37.81205px 0px rgba(255, 112, 73, 0.2);
            font: normal 500 14px/24px $font-family-primary;
            margin: 40px auto 7px auto;
            gap: 16px;
          }

          .mail-list {
            .mail-list-title {
              color: $email-list-title-color !important;
              font: normal 500 22px $font-family-tertiary !important;
              line-height: normal !important;
              .btn {
                padding: 0 !important;
                background-color: transparent;
                border: none;
                color: inherit !important;
                font-size: 18px;
              }
            }

            div[to="/main-inbox"].email-menu-active {
              span.rounded-circle.align-middle.me-3.badge.bg-black {
                background-color: $sidebar-menu-item-active-color !important;
              }

              .email-menu-icon {
                color: $sidebar-menu-item-active-color !important;
              }

              p.email-menu-title {
                color: #563bff;
                font-weight: 500 !important;
              }
            }

            .email-menu-icon {
              color: #141f27 !important;
              font: 16px $font-family-primary !important;
            }

            span.rounded-circle.align-middle.me-3.badge.bg-black {
              background-color: $sidebar-menu-item-icon-color;
              font-size: 75% !important;
            }

            .unseen-badge {
              font: normal 400 12px/16px $font-family-primary;
              color: $unseen-badge-color !important;
              opacity: 0.4;
            }

            p.email-menu-title {
              margin-bottom: 0 !important;
            }
          }

          #flush-headingFive {
            button.accordion-button {
              margin-top: 30px;
              padding: 8px 0 !important;
              color: #fff !important;
              font-family: "Montserrat", sans-serif;
              font-size: 13px;
              font-style: normal;
              font-weight: 400 !important;
              background-color: #141f27;
              border-radius: 10px;
              display: flex;
              justify-content: center;

              /* 150% */
              &::after {
                display: none;
              }

              span {
                font-family: Nunito;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                /* 120% */
              }
            }
          }
        }

        .inbox-mails-conatiner {
          background-color: #fdfdfc;
          border-left: 2px solid $inbox-mails-conatiner-border-color;
          border-right: 2px solid $inbox-mails-conatiner-border-color;

          div.mb-2.row {
            margin-bottom: 0 !important;

            div {
              .mails-separator {
                border-top-color: $mails-separator-bg;
                position: absolute;
                width: auto;
                left: 24px;
                right: 24px;
                bottom: 0px;
                margin: 0 !important;
              }

              .email-message-background {
                position: relative !important;
                background-color: rgba(86, 59, 255, 0.1) !important;
                border-radius: 0 !important;
                padding: 12px 12px 12px 9px !important;

                .email-message-actions {
                  margin-top: 6px;
                  display: flex !important;
                  flex-wrap: nowrap;

                  button {
                    font: normal 400 11px / normal $font-family-primary;
                    background-color: transparent;
                    border: none;
                    margin-right: 6px;
                  }

                  .message-action-starred {
                    color: #ffa412;
                  }

                  .message-action-moveto {
                    color: #05b714;
                  }

                  .message-action-trash {
                    color: #f10944;
                  }
                }

                &::before {
                  position: absolute;
                  top: 0;
                  left: 0;
                  content: "";
                  width: 3px;
                  height: 100%;
                  background-color: #563bff !important;
                }

                .mails-separator {
                  display: none !important;
                }
              }

              .email-message {
                padding: 12px 12px 12px 9px;
                position: relative;
              }

              .email-message:hover:not(.email-message-background) {
                border-radius: 0 !important;
                padding: 12px 12px 12px 9px !important;
                background-color: #f7f7f7;
              }

              .checkbox-container {
                display: flex;
                align-items: center;

                .form-check-input {
                  margin-top: 0;
                  margin-right: 13px;
                  width: 18px;
                  height: 18px;
                  border-radius: 6.756px;
                  border: 1px solid $email-message-box-border-color;
                  background-color: $email-message-box-background-color;

                  &:focus {
                    box-shadow: none;
                    border: none;
                  }

                  &.active {
                    border: none;
                  }

                  &:checked {
                    border: none;
                    background-color: $email-message-box-background-active;
                    box-shadow: none;
                  }
                }
              }

              .email-message-text {
                width: 196px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                // margin-right: 10px;
                font: normal 400 12px $font-family-primary !important;
                color: $email-message-text-color;
                line-height: normal;
              }

              .email-message-subject {
                width: 196px;
                color: $email-message-teaser;
                font: normal 600 12px $font-family-primary !important;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .avatarBadge {
                height: 40px;
                width: 40px;
                font-size: 140%;
              }

              .teaser {
                width: 196px;
                color: $email-message-teaser;
                overflow: hidden;
                text-overflow: ellipsis;
                font: normal 400 12px $font-family-primary !important;
              }

              .email-message-date {
                margin-left: auto;
                overflow: hidden;
                color: $email-message-date-color !important;
                text-overflow: ellipsis;
                text-align: end;
                font: normal 500 12px Satoshi, sans-serif !important;
              }

              .email-count {
                height: 24px;
                width: 24px;
                color: $email-count-badge-message-color;
                background-color: $email-count-badge-message-background;
                overflow: hidden;
                text-overflow: ellipsis;
                font: normal 900 12px / normal Satoshi !important;
              }
            }
          }

          #email-list-title {
            margin-top: auto;
            margin-bottom: auto;
            align-self: flex-start;
            color: $email-list-title-color !important;
            font: normal 500 22px $font-family-tertiary !important;
            line-height: normal !important;
          }

          .email-list-category {
            padding-right: 30px;
            margin-bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            color: $email-list-category-color;
            font: normal 300 18.296px / normal $font-family-primary !important;
            cursor: pointer;
          }

          .email-list-category-active {
            color: $email-list-category-active;
            font: normal 500 18.296px / normal $font-family-primary !important;
          }
        }

        .detail-bar {
          padding: 20px 15px 15px 15px !important;
          background-color: $detail-bar-bg-color;
          border-left: 2px solid $detail-bar-border-color;
          color: $detail-bar-color !important;

          .column-title {
            margin-bottom: 0;
            // color: $detail-bar-color;
            font: normal 500 20px/24px $font-family-tertiary !important;
          }

          .email-menu-bar {
            margin-top: 0 !important;
            margin-bottom: 0 !important;

            .detail-bar-menu-icon {
              width: 20px;
              height: 25.185px;
              flex-shrink: 0;
            }
          }

          .email-menu-bar-line {
            margin-top: 24px;
            margin-bottom: 15px;
            border-bottom-width: 1.5px !important;
          }

          i,
          span,
          p,
          button {
            color: $detail-bar-color !important;
          }

          span {
            font-family: $font-family-primary !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 18px !important;
          }

          span.font-weight-bold {
            font-family: $font-family-primary !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 500 !important;
            line-height: 24px !important;
          }

          .accordion-item {
            // width: 363px;
            border-bottom: 1.5px solid $detail-bar-accordion-item-border-color !important;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            button {
              padding: 15px 10px !important;
              font: normal 500 14px $font-family-tertiary !important;

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

#gauge-chart1 {
  svg {
    height: 44px !important;
    width: 77px !important;

    > g {
      height: 100% !important;
    }
  }
}

#sidebar-menu {
  .mm-active {
    > .has-arrow {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  .has-arrow {
    &:after {
      content: "\F0140";
      font-family: "Material Design Icons";
      display: block;
      float: right;
      transition: transform 0.2s;
      font-size: 1rem;
    }
  }

  ul {
    li {
      a {
        display: block;
        padding: 0.625rem 1.5rem;
        margin-bottom: 20px;
        color: $sidebar-menu-item-color;
        position: relative;
        font-size: 0.92rem;
        transition: all 0.4s;
        font-family: $font-family-secondary;
        border-radius: 0;
        i,
        svg {
          display: inline-block;
          min-width: 1.5rem;
          font-size: 1.1rem;
          line-height: 1.40625rem;
          vertical-align: middle;
          color: $sidebar-menu-item-icon-color;
          transition: all 0.4s;
          opacity: 1;
        }

        span {
          color: $sidebar-menu-sub-item-color;
        }

        // &:hover {
        //   color: $sidebar-menu-item-hover-color;
        //   background-color: $sidebar-menu-item-active-color !important;

        //   i,
        //   svg {
        //     color: $sidebar-menu-item-hover-color;
        //   }

        //   span {
        //     color: $sidebar-menu-item-hover-color;
        //   }
        // }
      }

      a.active {
        i.mdi,
        svg {
          color: #563bff !important;
        }

        .badge {
          color: #563bff !important;
          background-color: white !important;
        }
      }

      a.mm-active {
        i.mdi,
        svg {
          color: White !important;
        }
      }

      a.mm-active.mm-collapsed {
        i.mdi,
        svg {
          color: $sidebar-menu-item-icon-color;
        }
      }

      .badge {
        color: white !important;
        background-color: #563bff !important;
        margin-top: 4px;
      }

      ul.sub-menu {
        padding: 0;
        background-color: $sidebar-bg;

        li {
          a {
            padding: 0.4rem 1.5rem 0.4rem 3.2rem;
            font-size: 14px;
            color: $sidebar-menu-sub-item-color;

            &::before {
              position: relative !important;
              margin-right: 20px !important;
              content: " " !important;
              width: 12px !important;
              height: 10px !important;
              border-radius: 50% !important;
              background: 0 0 !important;
              border: 2px solid !important;
              left: -7px !important;
              border-color: transparent transparent #eaecf1 transparent !important;
              top: 14px !important;
              transition: all 0.4s ease-in-out !important;
            }
          }

          ul.sub-menu {
            padding: 0;

            li {
              a {
                padding: 0.4rem 1.5rem 0.4rem 4.2rem;
                font-size: 14px;

                &::before {
                  position: relative !important;
                  margin-right: 20px !important;
                }
              }
            }
          }
        }
      }

      &:hover {
        .badge {
          color: #563bff !important;
          background-color: white !important;
        }
      }
    }

    li.mm-active {
      .mm-show {
        .mm-active > a {
          color: white !important;
        }
      }
    }
  }
}

.menu-title {
  padding: 12px 20px !important;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: $sidebar-menu-item-color;
  background-color: $sidebar-bg;
  font-weight: $font-weight-semibold;
  font-family: $font-family-secondary;
  opacity: 1;
}

.mm-active {
  color: $sidebar-menu-item-active-color !important;

  > a {
    background-color: $sidebar-menu-item-active-bg !important;

    span {
      color: $sidebar-menu-item-color;
    }
  }

  > i {
    color: $sidebar-menu-item-active-color !important;
  }

  // .active {
  //   color: $sidebar-menu-item-active-color !important;

  //   i {
  //     color: $sidebar-menu-item-active-color !important;
  //   }
  // }
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
  }

  .main-content {
    margin-left: 0 !important;
  }

  body.sidebar-enable {
    .vertical-menu {
      display: block;
    }
  }
}

@media (max-width: 1399.98px) {
  // .avatarBadge {
  //   height: 29px !important;
  //   width: 29px !important;
  //   font-size: 70% !important;
  // }

  .inbox-menu {
    padding: 0 12px 0 14px !important;
    width: 172px !important;
  }

  .credit-card-digits {
    font-size: 16.724px !important;
  }

  .card-title {
    font-size: 14.487px;
  }

  .info {
    font-size: 10.927px !important;
  }

  .credit-card {
    height: 208.049px !important;
  }

  .add-credit-card {
    height: 208.049px !important;
  }
}

// Enlarge menu
.vertical-collpsed {
  .main-content {
    margin-left: $sidebar-collapsed-width;
  }

  .navbar-brand-box {
    width: $sidebar-collapsed-width !important;
    padding: 0;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: block;
    }
  }

  // Side menu
  .vertical-menu {
    border: none !important;
    position: absolute;
    width: $sidebar-collapsed-width !important;
    z-index: 5;

    .simplebar-mask,
    .simplebar-content-wrapper {
      overflow: visible !important;
    }

    .simplebar-scrollbar {
      display: none !important;
    }

    .simplebar-offset {
      bottom: 0 !important;
    }

    // Sidebar Menu
    #sidebar-menu {
      padding: 48px 0 30px 0;

      .menu-title,
      .collapse.in {
        display: none !important;
      }

      .nav.collapse {
        height: inherit !important;
      }

      .has-arrow {
        &:after {
          display: none;
        }
      }

      > ul {
        > li {
          position: relative;
          white-space: nowrap;

          > a {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;
            width: 53px;
            height: 46.857px;
            transition: none;

            // &:hover,
            // &:active,
            // &:focus {
            //   color: $sidebar-menu-item-hover-color;
            // }

            i,
            svg {
              text-align: center;
              margin: 0 !important;
              color: $sidebar-menu-item-icon-color;
              font-size: 20px;
            }

            .menu-item-label {
              color: $sidebar-menu-item-color;
              display: none;
              padding-left: 20px;
            }
          }

          // &:hover {
          //   >a {
          //     // display: flex;
          //     // justify-content: left;
          //     // align-items: center;
          //     // padding: 0px 20px;
          //     // width: calc(190px + #{$sidebar-collapsed-width});
          //     color: $primary;
          //     background-color: $sidebar-bg !important;
          //     transition: all 0.4s;

          //     // i,
          //     // svg {
          //     //   // margin-right: 5px !important;
          //     //   // margin-left: 10px !important;
          //     //   color: $sidebar-menu-item-hover-color;
          //     // }

          //     // span {
          //     //   display: inline;
          //     // }
          //   }

          //   >ul {
          //     display: block;
          //     left: $sidebar-collapsed-width;
          //     position: absolute;
          //     width: 207px;
          //     height: auto !important;
          //     box-shadow: 3px 5px 12px -4px rgba(18, 19, 21, 0.1);

          //     ul {
          //       box-shadow: 3px 5px 12px -4px rgba(18, 19, 21, 0.1);
          //     }

          //     a {
          //       box-shadow: none;
          //       padding: 8px 20px;
          //       position: relative;
          //       width: 190px;
          //       z-index: 6;
          //       color: $sidebar-menu-sub-item-color;

          //       // &:hover {
          //       //   color: $sidebar-menu-item-hover-color;
          //       // }
          //     }
          //   }
          // }
        }

        a.mm-active {
          > i,
          svg {
            color: white !important;
          }
        }

        > .mm-active {
          position: relative;

          &::before {
            position: absolute;
            left: -3.241px;
            top: 7px;
            content: " ";
            background-color: $sidebar-menu-item-active-color;
            height: 34px;
            width: 6.482px;
            border-radius: 0 50px 50px 0;
            z-index: 2;
          }

          // >.active {
          //   width: 44px;
          //   height: 46.857px;
          //   padding: 0;

          //   i,
          //   svg {
          //     color: white !important;
          //   }
          // }

          // &:hover {
          //   >a {
          //     display: flex;
          //     justify-content: left;
          //     align-items: center;
          //     padding: 0px 20px;

          //     position: relative;
          //     width: calc(190px + #{$sidebar-collapsed-width});
          //     background-color: darken($sidebar-bg, 4%);
          //     transition: all 0.4s;

          //     i,
          //     svg {
          //       margin-right: 5px !important;
          //       margin-left: 10px !important;
          //     }
          //   }
          // }
        }

        ul {
          margin-top: -19px;
          padding: 0;
          border-radius: 0 16px 16px;
          z-index: 9999;
          display: none;
          border-radius: 6;
          background-color: $sidebar-bg;

          li {
            &:hover {
              > ul {
                display: block;
                left: 190px;
                height: auto !important;
                margin-top: -36px;
                position: absolute;
                width: 190px;
              }
            }

            > a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }

          // .mm.active {
          //   color: white !important;
          // }

          // li.active {
          //   a {
          //     color: $gray-100;
          //   }
          // }
        }
      }
    }
  }
}

body[data-sidebar="dark"] {
  .overview_row {
    .card {
      background: #1e222c !important;
    }
  }

  .section__title__md {
    color: white !important;
  }

  .section__title {
    color: white !important;
  }

  .custom_dropdown_toggle {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .attention_requested {
    .accordion-item {
      background-color: #1e222c;

      .accordion-button {
        background-color: #1e222c;
        color: #fff;

        &::after {
          box-shadow: 0px 4.09856px 17.41886px 0px rgba(53, 22, 252, 0.1);
        }
      }

      .accordion-body__text {
        color: #fff !important;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjciIGhlaWdodD0iNjciIHZpZXdCb3g9IjAiIHN0eWxlPSJmaWxsOiMjMjJCMzQiLz4KPGYgZmlsdGVyPSJ1cmwoI2ZpbHRlck0xIDAsIDI2MCk+PGNpcmNsZSBjeD0iMzMuMzY5NiIgY3k9IjI5LjM2OTYiIHJlPSIxNS4zNjk2IiBmaWxsPSIjMjcyQjM0Ij48L2NpcmNsZT48L2Y+CjxkZWZzPjwvZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMCBkXzM0NF84Mjg2IiB4PSIwLjU4MTEzOSIgeT0iMC42Nzk2OTQiIHdpZHRoPSI2NS41NzYiIGhlaWdodD0iNjUuNTc3IiBmaWxsPSIjMjcyQjM0Ij48Y2x1c3MgY3g9IjMzLjM2OTYiIGN5PSIyOS4zNjkyIiByPSIxNS4zNjk2IiBmb2xkZXI9IiMyNzJCMzQiLz48L2ZpbHRlcj48ZGVmIGlkPSJmaWx0ZXIwIGRfMzQ0XzgyODYiIHg9IjAuNTgxMTM5IiB5PSIwLjY3OTY5NCIgd3d3LXdyaXRlPSJ1c2VyU3BhY2VPV04iIGNvbG9yLWludGVyYXBpc3Rpb24tZmlsdGVycz0ic1JHQiI+PGZlRmxvdCBmbG91ZC1vcGFjaXR0aW9uPSIwIiByZXN1bHQ9IkJhZ3NpYTwvZmVGbG93PjxmaUVmbG9vciBpbiJTb3V0aEFscGhhIHRvPSIwIiByZXN1bHQ9IkJhZ3NpYTwvZmVGbG9vciPjPGZlQ29sb3JNYXh0ImlkPSJmaWx0ZXIwIGJ5PjA8IiByZXN1bHQ9IkJhZ3NpYTwvZmVDb2xvcjI+PGZlRmxvdCBzeW5kaWNhdGlvbj0iZm91bmQtb3BwYXNzaW5nLWZpeGVycyIgcmVzdWx0PSJlYXN0dTEwMCIgcmVzdWx0LXNwb3RkZXN0IjoiMTMxIj4mIzAwMDtGb0xvb2QgZmxvdCBrZXlzPSJmbmJpbmcgaW4gdHJhbnNhcyIgcmVzdWx0PSJCkKFtKTCCkCIsIDEwMjQuOTg3NSwgMzU4MC44Mzc2OTggZmlsbCAiYmx1ZSBtYXJrZXQxMCwgMTY1LjU1ODgiLCAxMTQ0LCAwLjIyODc2OTQiPC8vZmVGbG93PjwvZmVDb2xvcj48L2ZpbHRlcz4K);
      }
    }
  }

  .global_view {
    .card {
      background: #272b34;
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);

      .col-2 {
        color: white;
      }
    }
  }

  .usals-tags {
    background: #1e222c;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);

    .legend-text {
      color: white;
      // font-size: 12px !important;
    }
  }

  .languages__card {
    background: #1e222c;
    border-radius: 20px;

    .customLegend {
      span {
        color: white;
      }
    }
  }

  .attention_languages__text {
    color: #fff;
  }

  .attention_languages {
    background: #1e222c;

    .recharts-wrapper {
      background: #272b35 !important;
    }

    .customLegend {
      span {
        color: #fff;
      }
    }
  }

  .attention_languages__language {
    .card {
      background: #1e222c;
    }
  }

  .global-view {
    background: #1e222c;

    .apex-charts {
      background: #272b35 !important;
    }

    .customLegend {
      span {
        color: white;
      }
    }
  }

  .analyseMessageSavHeure_card {
    background: #1e222c;

    .customGraph_yAxis__value,
    .customGraph_xAxis__value {
      color: white;
    }
  }

  .analyseMessageSavJour_card {
    background: #1e222c;

    .customGraph_yAxis__value,
    .customGraph_xAxis__value {
      color: white;
    }
  }

  .client-satisfaction {
    background: #1e222c;

    .customLegend {
      span {
        color: white;
      }
    }
  }

  .realtime-stats {
    border-radius: 10px;
    background: #1e222c;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);

    .cream-light {
      background: #272b34 !important;
    }

    .box-value-dark-blue {
      color: white !important;

      sup.color-dark-blue {
        color: white !important;
        font-size: 14px !important;
      }
    }

    .box-title-dark-blue {
      color: white !important;
    }

    circle[fill="#091B3D"] {
      fill: #f10944;
    }
  }

  .dashboard-clients {
    background: #272b34;

    p {
      color: white;
    }

    &:hover {
      p {
        color: #aaa;
      }
    }
  }

  .client-feedbacks {
    button {
      background-color: #1e222c !important;
    }
  }

  .response-rate-progression {
    border-radius: 20px;
    background: #1e222c;
  }

  .tasks-list-col {
    .card {
      background: #1e222c;
    }

    .task-message-text {
      color: white;
    }
  }

  .realtime-map {
    .card {
      background: #1e222c !important;
      box-shadow: 0px 6px 69px 1px rgba(53, 22, 252, 0.05) !important;
    }
  }

  .last_mails {
    .card {
      background: #272b35;
    }

    .email-message-text {
      color: white;
    }

    .email-message-subject {
      color: white;
    }

    .teaser {
      color: white;
    }
  }

  .onBoarding__content {
    .onBoarding__header_username {
      color: white !important;
    }

    .stepsInfo_text,
    .contactUs {
      color: white !important;
    }

    .stepContainer {
      background: #1e222c;
      box-shadow: 0px 6px 24px 1px rgba(0, 0, 0, 0.1);
    }

    .TabWiz__container {
      background-color: #1e222c !important;
    }

    .TabWizActive {
      background-color: #563bff !important;
    }

    .stepTitle {
      color: white !important;
    }

    .stepSubTitle {
      color: white !important;
    }

    .link-butons-prev {
      color: white !important;
    }

    .TabWiz {
      background-color: #1e222c;
    }

    .TabWizCompleted {
      background-color: #3115e2;
    }

    .tags_label {
      background: #363a45 !important;
    }

    .tags_card {
      background-color: #272b35 !important;
      box-shadow: 0px 6px 24px 1px rgba(0, 0, 0, 0.1) !important;
    }

    .tags_title {
      color: #fff !important;
    }
    label[for="tagInput"] {
      background-color: #363a45 !important;
    }
    .tags-card {
      background: #272b35;
      box-shadow: 0px 6px 24px 1px rgba(0, 0, 0, 0.1);
      .card-body > span {
        color: #fff !important;
      }
    }
    .tagsCustom_accordion {
      .accordion-item {
        background: #272b35 !important;
      }
      .accordion-text,
      .accordion-line-span {
        color: #fff !important;
      }
      .accordion-body-line {
        .input-group,
        .form-control {
          background: #363a45 !important;
        }
      }
    }

    .store-connection {
      background: #272b35 !important;
      box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.1) !important;

      .fa-shopify {
        color: #272b35 !important;
      }

      .store-title {
        div {
          color: #fff;
        }
      }

      .form-label {
        color: $white !important;
      }

      .form-control,
      .input-group {
        background-color: #363a45 !important;
      }
    }

    .store-type-container {
      .card-title,
      p {
        color: #fff !important;
      }
    }

    .mail-config_body {
      background-color: #1e222c;

      .form-control {
        background-color: #363a45 !important;
      }
    }

    .emailServer_div {
      background: #272b35 !important;

      h5 {
        color: white !important;
      }
    }

    .emailServer_div-selected {
      background: #fffaf1 !important;

      h5 {
        color: #ffa412 !important;
      }
    }

    .form-label {
      color: white !important;
    }

    .form-select,
    .multi-range-slider {
      background-color: #363a45 !important;
    }

    .agentcard__Container {
      background-color: #272b35 !important;

      .form-control {
        background-color: #363a45 !important;
      }

      .user-name,
      .profile-activity,
      .form-check-label,
      .min-caption,
      .max-caption {
        color: white !important;
      }

      // label[for="floatingSelectGrid"] {
      //    &::after {
      //       background: #363A45 !important;
      //     }
      //   }
    }
  }

  .main-content {
    background: #363a45;
    #layout-wrapper {
      > div {
        .cols-container {
          > .inbox-menu {
            background-color: $inbox-menu-dark-bg !important;

            .email-menu-active {
              .email-menu-title {
                color: $email-menu-dark-title-color !important;
              }
            }

            .unseen-badge {
              color: $inbox-menu-dark-unseen-badge-color !important;
            }

            #flush-headingFive {
              button.accordion-button {
                color: $inbox-menu-dark-accordion-button-color !important;
              }
            }
          }

          .inbox-mails-conatiner {
            border-left: 2px solid #383a45 !important;
            background: #1e222c !important;

            > div.input-group {
              background: #363a45 !important;
            }

            #email-list-title {
              color: $email-list-title-dark-color !important;
            }

            .fetch-mail-button {
              background-color: transparent !important;
              color: #fff !important;
            }

            .email-list-category-active {
              color: $email-list-category-active-dark !important;
            }

            .email-message {
              .email-message-text {
                color: $email-message-text-dark-color !important;
              }

              .email-message-subject {
                color: $email-message-teaser-dark !important;
              }

              .teaser {
                color: $email-message-teaser-dark !important;
              }
            }

            .email-message:hover:not(.email-message-background) {
              background-color: rgba(86, 59, 255, 0.1) !important;
            }

            .mails-separator {
              border-top-color: #6b6c6d !important;
            }
          }

          .detail-bar {
            background-color: $detail-bar-bg-dark-color;
            border-left: 1px solid $detail-bar-border-dark-color;
            color: $detail-bar-dark-color !important;

            .email-menu-bar-line {
              border-bottom-color: #6b6c6d !important;
            }

            i,
            span,
            p,
            button {
              color: $detail-bar-dark-color !important;
            }

            .accordion-item {
              border-bottom: 1.5px solid
                $detail-bar-accordion-item-border-dark-color !important;
            }
          }
        }
      }
    }
  }

  .vertical-menu {
    background: $sidebar-dark-bg;
  }

  #sidebar-menu {
    ul {
      li {
        a {
          color: $sidebar-dark-menu-item-color;

          i {
            color: $sidebar-dark-menu-item-icon-color;
          }

          &:hover {
            color: $sidebar-dark-menu-item-hover-color;

            i {
              color: $sidebar-dark-menu-item-hover-color;
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: $sidebar-dark-menu-sub-item-color;

              &:hover {
                color: $sidebar-dark-menu-item-hover-color;
              }
            }
          }
        }
      }
    }
  }

  .modal {
    background: rgba(54, 58, 69, 0.5);
    backdrop-filter: blur(16px);
    .modal_title {
      color: #fff;
    }
    .close_button {
      color: white !important;
    }
    .modal-content {
      border: none;
      background: #1e222c;
      box-shadow: 0px 4px 82.5px 0px rgba(0, 0, 0, 0.25);
    }
    .modal-title,
    .form-label {
      color: #fff !important;
    }
    .form-control {
      background-color: #363853 !important;
      color: #fff;
    }

    .modal-header {
      background: #1e222c !important;
    }

    .help-page-modal {
      .help-page-modal-button {
        border: 1px solid #363853;
        background: #363853;
        color: #fff !important;
        h3 {
          color: #fff !important;
        }
      }
    }
    .billing-page-modal {
      .addOn_label {
        color: #fff;
      }
    }
    .tutorial-video-modal {
      .body-text {
        color: #fff;
      }
    }
  }

  .user-modal {
    .font-tertiary {
      color: #fff !important;
    }
  }

  // Ressources
  .ressources-content {
    .simple-text {
      color: #fff !important;
    }

    .ressourcesList_item {
      background: #272b35;
    }

    .ressource-name {
      color: #fff;
    }

    .category-dropdown {
      background: #363a45;
      color: #fff;
    }

    .text-area {
      background: #363a45;
      color: #fff;
    }

    .pagination-style {
      color: #e3e8e7;
    }

    .currentPage {
      background: #272b35;
      box-shadow: 0px 4px 13.2px 0px rgba(0, 0, 0, 0.1);
    }
  }

  // Help
  .help-page {
    .input-group {
      color: #e0e0e0 !important;
      background-color: #363853 !important;
      border: solid 1px #151d48;
    }
    .help__section_card {
      border-radius: 10px;
      background: #1e222c;
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);
    }
    .help__section_title {
      color: #fff;
    }
    .help__section_articles {
      color: #e3e8e7;
    }
  }

  // Details
  .details-page {
    .details-page-content,
    .details-link {
      color: #fff;
    }
  }

  // Settings
  .settings-content {
    .section-title {
      color: #fff !important;
    }
    .settings-menu {
      .nav {
        .nav-link {
          color: #e3e8e7;
        }
        .active {
          color: #ff7049;
        }
      }
    }
    .menuTopScroll {
      background-color: #363a45 !important;
    }

    .general {
      .store-connection {
        background: #272b35 !important;
        box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.1) !important;

        .fa-shopify {
          color: #272b35 !important;
        }

        .store-title {
          div {
            color: #fff;
          }
        }

        .form-label {
          color: $white !important;
        }

        .form-control,
        .input-group {
          background-color: #363a45 !important;
        }

        .stepTitle,
        .card-title,
        .stepSubTitle,
        .simple-text {
          color: #fff;
        }
      }
      .ressources {
        background: #272b35 !important;
        box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.1) !important;
        .stepTitle,
        .stepSubTitle {
          color: white !important;
        }
        label[for="tagInput"] {
          background-color: #363a45 !important;
        }
        .tags-card {
          background: #272b35;
          box-shadow: 0px 6px 24px 1px rgba(0, 0, 0, 0.1);
          .card-body > span {
            color: #fff !important;
          }
        }
        .tagsCustom_accordion {
          .accordion-item {
            background: #272b35 !important;
          }
          .accordion-text,
          .accordion-line-span {
            color: #fff !important;
          }
          .accordion-body-line {
            .input-group,
            .form-control {
              background: #363a45 !important;
            }
          }
        }
      }
    }
    .facturation {
      .simple-text,
      .form-check-label,
      h5 {
        color: #fff !important;
      }
      .nav-item > a {
        color: #fff;
        &[class="active active"] {
          color: #563bff;
        }
      }
      .payment-information {
        .form-input,
        .form-check-input {
          color: #fff !important;
          &::placeholder {
            color: #ffffff59 !important;
          }
        }
      }
      .table-card,
      .rdt_TableHeadRow,
      .rdt_TableRow {
        background: #1e222c !important;
      }
      .cell-title,
      .cell-header,
      .totalUsed_text,
      i.ri-more-fill {
        color: #fff !important;
      }
      .cell-subtitle {
        color: #c7c7d2;
      }
      .avatar-xs {
        background: #363853;
      }
    }
    .email {
      .mail-config_card {
        background-color: #1e222c !important;
      }
      .mail-config_body {
        background-color: #1e222c !important;

        .form-control {
          background-color: #363a45 !important;
        }

        .mail-config_body {
          background-color: #1e222c;

          .form-control {
            background-color: #363a45 !important;
          }
        }

        .emailServer_div {
          background: #272b35 !important;

          h5 {
            color: white !important;
          }
        }

        .emailServer_div-selected {
          background: #fffaf1 !important;

          h5 {
            color: #ffa412 !important;
          }
        }

        .form-label {
          color: white !important;
        }
      }
      .card-subtitle,
      .card-title,
      h4 {
        color: #fff !important;
      }
    }
    .profile {
      .card {
        background: #1e222c;
      }
      div[type="button"],
      button,
      .form-label {
        color: #fff !important;
      }

      .inner_section_title_1,
      .inner_section_title_2,
      .font-primary,
      .font-tertiary {
        color: #fff !important;
      }

      .form-control {
        background-color: #272b35 !important;
        color: #dedede;
      }
      .dropdown-menu {
        background-color: #272b35;
      }
      .dropdown-item {
        &:hover {
          background-color: #272b35;
        }
      }

      #floatingSelectGrid {
        background-color: #272b35;
        border: none;
        color: white;
      }
      label[for="floatingSelectGrid"] {
        p {
          color: #fff;
        }
        &::after {
          background-color: #272b35 !important;
        }
      }
    }
    .users-tabpane {
      h5,
      .user-name,
      .user-info,
      .agent-name,
      .user-autorizations {
        color: white !important;
      }
      .email-menu-dropdown {
        background: #272b35 !important;
      }
      .user-card {
        background: #272b35 !important;
        box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.1) !important;
      }
    }
    .agents-tabpane {
      h5,
      .agent-name,
      .agent-info,
      .agent-autorizations {
        color: white !important;
      }
      .agent-card {
        background: #272b35 !important;
        box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.1) !important;
      }
    }
    .notifications {
      .notifications_title,
      .notifications_teaser {
        color: #fff;
      }
    }
    .notifications_item {
      background: #1e222c;
    }
    .custom_switch {
      .react-switch-bg {
        background: #363853 !important;
      }
    }
  }

  .introjs-helperLayer {
    box-shadow: rgba(0, 0, 0, 0.41) 0px 0px 0px 5000px !important;
  }

  .introjs-tooltiptext,
  .introjs-skipbutton {
    color: #fff;
  }

  .introjs-tooltip {
    background-color: #3b3d43;
  }

  .introjs-bullets ul li a {
    background: #fff;
  }

  // Enlarge menu
  &.vertical-collpsed {
    // min-height: 1400px;

    // Side menu
    .vertical-menu {
      border-right-color: #383a45 !important;

      // Sidebar Menu
      #sidebar-menu {
        > ul {
          > li {
            &:hover {
              > a {
                background: lighten(
                  $sidebar-dark-menu-item-active-bg-color,
                  2%
                ) !important;
                color: $sidebar-dark-menu-item-hover-color;

                i {
                  color: $sidebar-dark-menu-item-hover-color;
                }
              }

              > ul {
                a {
                  color: $sidebar-dark-menu-sub-item-color;

                  &:hover {
                    color: $sidebar-menu-item-hover-color;
                  }
                }
              }
            }
          }

          ul {
            background-color: #563bff;
          }
        }

        ul {
          > li {
            > a {
              &.mm-active {
                color: $sidebar-dark-menu-item-active-color !important;
              }

              &:hover {
                background-color: $sidebar-dark-menu-item-hover-bg-color !important;
              }
            }
          }

          li {
            li {
              &.mm-active,
              &.active {
                > a {
                  // color: $sidebar-menu-item-active-color !important;
                }
              }

              a {
                &.mm-active,
                &.active {
                  // color: $sidebar-menu-item-active-color !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .mm-active {
    color: $sidebar-dark-menu-item-active-color !important;

    > a {
      color: $sidebar-dark-menu-item-active-color !important;

      i {
        color: $sidebar-dark-menu-item-active-color !important;
      }

      background-color: $sidebar-dark-menu-item-active-bg-color !important; //new style added
    }

    > i {
      color: $sidebar-dark-menu-item-active-color !important;
    }

    // .active {
    //   color: $sidebar-dark-menu-item-active-color !important;

    //   i {
    //     color: $sidebar-dark-menu-item-active-color !important;
    //   }
    // }
  }

  .menu-title {
    color: $sidebar-dark-menu-item-icon-color;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Couleur de fond de la piste */
  }
}

body[data-layout="horizontal"] {
  .main-content {
    margin-left: 0 !important;
  }
}

// Compact Sidebar

body[data-sidebar-size="small"] {
  .navbar-brand-box {
    width: $sidebar-width-sm;

    @media (max-width: 992px) {
      width: auto;
    }
  }

  .vertical-menu {
    width: $sidebar-width-sm;
    text-align: center;

    .has-arrow:after,
    .badge {
      display: none !important;
    }
  }

  .main-content {
    margin-left: $sidebar-width-sm;
  }

  .footer {
    left: $sidebar-width-sm;

    @media (max-width: 991px) {
      left: 0;
    }
  }

  #sidebar-menu {
    ul li {
      &.menu-title {
        background-color: lighten($sidebar-dark-bg, 2%);
      }

      a {
        i {
          display: block;
        }
      }

      ul.sub-menu {
        li {
          a {
            padding-left: 1.5rem;
          }

          ul.sub-menu {
            li {
              a {
                padding-left: 1.5rem;
              }
            }
          }
        }
      }
    }
  }

  &.vertical-collpsed {
    .main-content {
      margin-left: $sidebar-collapsed-width;
    }

    .vertical-menu {
      #sidebar-menu {
        text-align: left;

        > ul {
          > li {
            > a {
              i {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .footer {
      left: $sidebar-collapsed-width;
    }
  }
}

// oBoarding

.notifications {
  .notifications_item {
    border-radius: 30px;
    background: #fff;
    padding: 36px 20px;
    margin-bottom: 30px;

    .form-check-input {
      width: 27px;
      height: 27px;
      border-radius: 8px;
      border: 1.5px solid #e3e8e7;
      background-color: #fff;

      &:checked {
        background-color: #05b714;
      }

      &:focus {
        box-shadow: 0 0 0 0.15rem rgba(5, 184, 20, 0.25);
      }
    }
    .card-body {
      padding: 0 !important;
    }
  }

  .form-check-input {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    border: 1.5px solid #d9d9d9;
    background-color: #fff;

    &:checked {
      background-color: #05b714;
    }

    &:focus {
      box-shadow: 0 0 0 0.15rem rgba(5, 184, 20, 0.25);
    }
  }

  .form-control {
    height: 55px;
    border-radius: 10px;
    background-color: #f2f4fb !important;
    border: none;
    font-family: $font-family-primary;
    line-height: $font-height-simple-text;
    font-size: $font-size-simple-text;
    border: none !important;

    &:focus {
      background-color: #f2f4fb !important;
    }
    &:disabled {
      cursor: not-allowed !important;
    }
  }

  .intl-tel-input .form-control {
    &:focus {
      background-color: #f2f4fb !important;
    }
  }

  .notifications_title {
    color: #272b35;
    font-family: $font-family-tertiary;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .notifications_teaser {
    color: #737791;
    font-family: $font-family-primary;
    font-size: $font-size-simple-text;
    font-style: normal;
    font-weight: 400;
    line-height: $font-height-simple-text;
  }

  .notifications_badge {
    min-width: 54px;
    height: fit-content;
    padding: 4.7px 12.7px;
    text-align: center;
    border-radius: 4.973px;
    text-align: center;
    font-family: $font-family-primary;
    font-size: 12.432px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.927px;
  }

  .new {
    background: #e8f7f7;
    color: #329993;
  }

  .unread {
    background: #fffaf1;
    color: #ffa412;
  }

  .delete {
    background: #ffe4eb;
    color: #f10944;
  }

  .custom_switch {
    width: 133px !important;
    height: 45px !important;
    border-radius: 7.5px !important;

    .react-switch-bg {
      width: 133px !important;
      height: 45px !important;
      border-radius: 7.5px !important;
      background: #f5f5f5 !important;
      > div {
        height: 100% !important;
        width: 50% !important;
      }
    }

    .react-switch-handle {
      border-radius: 7.5px !important;
      width: 57px !important;
      height: 36px !important;
      transform: translateX(5px) !important;
      top: 5px !important;
      background: #f10944 !important;
      box-shadow: none !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-family: Nunito, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      // &::after {
      // content: 'NON';
      //   color: #FFF;
      //   font-family: Nunito, sans-serif;
      //   font-size: 18px;
      //   font-style: normal;
      //   font-weight: 600;
      //   line-height: normal;
      // }
    }

    .react-switch-handle[style="height: 26px; width: 26px; background: rgb(255, 255, 255); display: inline-block; cursor: pointer; border-radius: 50%; position: absolute; transform: translateX(29px); top: 1px; outline: 0px; border: 0px; transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;"] {
      border-radius: 7.5px !important;
      width: 57px !important;
      height: 36px !important;
      transform: translateX(70px) !important;
      top: 5px !important;
      background: #329993 !important;
      color: #fff;
      font-family: Nunito, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      // &::after {
      //   // content: 'OUI';
      //   color: #FFF;
      //   font-family: Nunito, sans-serif;
      //   font-size: 18px;
      //   font-style: normal;
      //   font-weight: 600;
      //   line-height: normal;
      // }
    }
  }
}

// Settings

.settings-content {
  padding-top: 102px !important;
  .section-title {
    color: #151d48;
    font-family: $font-family-tertiary;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
  .menuTopScroll {
    background-color: #fdfdfc !important;
  }
  .settings-menu {
    .nav {
      border-bottom: 1px solid #cacaca;
      .nav-link {
        font-size: $font-size-simple-text !important;
        font-family: $font-family-primary !important;
        line-height: $font-height-simple-text !important;
        color: #737791;
        border-radius: 0;
        height: 100%;
      }

      .active {
        background-color: transparent;
        color: #151d48;
        font-weight: 600;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -2px;
          background-color: #ff7049 !important;
          height: 3px !important;
          width: 100% !important;
        }
      }
    }
  }

  .general {
    #floatingSelectGrid {
      color: #091b3d;
      font-family: $font-family-primary;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 27px;
      padding-bottom: 0px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64' fill='none'%3E%3Cg filter='url(%23filter0_d_1148_1762)'%3E%3Crect x='12' y='8' width='40' height='40' rx='6.75556' fill='%23FFFAF1'/%3E%3C/g%3E%3Cpath d='M25 24L32.5 32L40 24' stroke='%23FFA412' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_1148_1762' x='0.9' y='0.9' width='62.2' height='62.2' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='5.55'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1148_1762'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1148_1762' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
      background-size: auto;

      option {
        font-size: 16px;
      }
    }

    label[for="floatingSelectGrid"] {
      padding-left: 27px;
      padding-top: 20px !important;
      color: #758a89;
      font-family: $font-family-primary;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &::after {
        background-color: #fff !important;
      }
    }

    .form-check {
      height: 55px;
    }

    .form-check-input {
      width: 22px;
      height: 22px;
      border-radius: 6.756px;
      border: 1px solid #e3e8e7;
      background-color: #fff;

      &:checked {
        background-color: #05b714;
      }

      &:focus {
        box-shadow: 0 0 0 0.15rem rgba(5, 184, 20, 0.25);
      }
    }

    .form-check-label {
      color: #171c1b;
      font-family: $font-family-primary;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .flatpickr-input {
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.05);
      color: #758a89 !important;
      font-family: $font-family-primary;
      font-size: $font-size-simple-text;
      font-style: normal;
      font-weight: 500;
      line-height: $font-height-simple-text;

      &::placeholder {
        color: #758a89 !important;
      }
    }

    .form-control.flatpickr-input:disabled {
      &::placeholder {
        color: #dedede !important;
      }
    }

    .action-btn {
      border-radius: 16.205px;
      background: #ff7049;
      padding: 14px 26px;
      box-shadow: 0px 16.20517px 37.81205px 0px rgba(255, 112, 73, 0.2);
      color: #fff;
      font-family: $font-family-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .form-label {
      color: #828282;
      font-family: $font-family-primary;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .input-group-text {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background: #f7f8fb;
      border: none;
      color: #b3b3b3 !important;
      font-family: $font-family-primary;
      font-size: $font-size-simple-text;
      font-style: normal;
      font-weight: 400;
      line-height: $font-height-simple-text;
    }

    .company-infos__container {
      .form-control {
        border-radius: 8px !important;
        background: #f7f8fb !important;
        color: #b3b3b3 !important;
        font-family: $font-family-primary;
        font-size: $font-size-simple-text;
        font-style: normal;
        font-weight: 400;
        line-height: $font-height-simple-text;

        &::placeholder {
          color: #000 !important;
        }
      }
    }
  }

  .email {
    .card-title {
      color: #273532;
      font-family: $font-family-tertiary;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .card-subtitle {
      color: #666969;
      font-family: $font-family-primary;
      font-size: $font-size-simple-text;
      font-style: normal;
      font-weight: 400;
      line-height: $font-height-simple-text;
    }

    .input-group-text {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background: #f7f8fb;
      border: none;
      color: #b3b3b3 !important;
      font-family: $font-family-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .form-check-input {
      width: 18px;
      height: 18px;
      border-radius: 6.756px;
      border: 1px solid #e3e8e7;
      background-color: #fff;

      &:checked {
        background-color: #05b714;
      }

      &:focus {
        box-shadow: 0 0 0 0.15rem rgba(5, 184, 20, 0.25);
      }
    }

    .form-check-label {
      color: #000;
      font-family: $font-family-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .action-btn {
      border-radius: 16.205px;
      background: #ff7049;
      padding: 13px 26px;
      box-shadow: 0px 16.20517px 37.81205px 0px rgba(255, 112, 73, 0.2);
      color: #fff;
      font-family: $font-family-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .profile {
    .card {
      border-radius: 15px;
    }

    div[type="button"],
    button {
      border-radius: 10px !important;
    }
    button[type="button"],
    button {
      border-radius: 20px !important;
    }
    .btn-orange {
      color: #fff !important;
      font-weight: 600;
      border: none !important;
      background: #ff7049 !important;
      box-shadow: 0px 16.21px 37.81px 0px rgba(255, 112, 73, 0.2);
    }

    .action-div {
      display: flex;
      justify-content: end;
      font-family: $font-family-primary;
    }

    .form-label {
      font-family: $font-family-tertiary !important;
      font-weight: 500 !important;
    }
    .inner_section_title_2 {
      font-size: 16px;
      font-weight: 500 !important;
    }
    .inner_section_title_1 {
      font-size: 18px;
      font-weight: 600 !important;
    }
    .form-control {
      height: 55px;
      border-radius: 10px;
      background-color: #f2f4fb !important;
      font-family: $font-family-primary;
      line-height: $font-height-simple-text;
      font-size: $font-size-simple-text !important;
      border: none;
      &:focus {
        background-color: #f2f4fb !important;
      }
    }
  }

  .notifications {
    .notifications_item {
      border-radius: 30px;
      background: #fff;
      padding: 36px 20px;
      margin-bottom: 30px;

      .card-body {
        padding: 0 !important;
      }
    }

    .form-check-input {
      width: 28px;
      height: 28px;
      border-radius: 6px;
      border: 1.5px solid #d9d9d9;
      background-color: #fff;

      &:checked {
        background-color: #05b714;
      }

      &:focus {
        box-shadow: 0 0 0 0.15rem rgba(5, 184, 20, 0.25);
      }
    }

    .notifications_title {
      color: #272b35;
      font-family: $font-family-tertiary;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    .notifications_teaser {
      color: #737791;
      font-family: $font-family-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21.5px;
    }

    .notifications_badge {
      min-width: 54px;
      height: fit-content;
      padding: 4.7px 12.7px;
      text-align: center;
      border-radius: 4.973px;
      text-align: center;
      font-family: $font-family-primary;
      font-size: 12.432px;
      font-style: normal;
      font-weight: 400;
      line-height: 17.927px;
    }

    .new {
      background: #e8f7f7;
      color: #329993;
    }

    .unread {
      background: #fffaf1;
      color: #ffa412;
    }

    .delete {
      background: #ffe4eb;
      color: #f10944;
    }

    .custom_switch {
      width: 133px !important;
      height: 45px !important;
      border-radius: 7.5px !important;

      .react-switch-bg {
        width: 133px !important;
        height: 45px !important;
        border-radius: 7.5px !important;
        background: #f5f5f5 !important;
        > div {
          height: 100% !important;
          width: 50% !important;
        }
      }

      .react-switch-handle {
        border-radius: 7.5px !important;
        width: 57px !important;
        height: 36px !important;
        transform: translateX(5px) !important;
        top: 5px !important;
        background: #f10944 !important;
        box-shadow: none !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        &::after {
          // content: 'NON';
          color: #fff;
          font-family: Nunito, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .react-switch-handle[style="height: 26px; width: 26px; background: rgb(255, 255, 255); display: inline-block; cursor: pointer; border-radius: 50%; position: absolute; transform: translateX(29px); top: 1px; outline: 0px; border: 0px; transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;"] {
        border-radius: 7.5px !important;
        width: 57px !important;
        height: 36px !important;
        transform: translateX(70px) !important;
        top: 5px !important;
        background: #329993 !important;
        &::after {
          // content: 'OUI';
          color: #fff;
          font-family: Nunito, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }

  .facturation {
    .nav-item {
      margin-bottom: 40px;

      a {
        padding: 8px 21px;
        color: #737791;
        font-family: $font-family-primary;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 200%;
        /* 44px */

        &[class="active active"] {
          color: #563bff;
          border-radius: 8px;
          background: #dfdaff;
          box-shadow: 0px 16px 37px 0px rgba(223, 218, 255, 0.15);
        }
      }
    }

    .offer-card {
      padding: 20.86px 14.21px 20.92px 14.05px;
      border-radius: 30px;
      background: #e8f7f7;
    }

    .offer-title {
      text-align: center;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .offer-price {
      text-align: center;
      color: #329993;
      font-family: "Montserrat", sans-serif;
      font-size: 33px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .option-text-avalaible {
      margin-bottom: 20px;
      color: #313131;
      font-family: Inter, sans-serif;
      font-size: $font-size-simple-text;
      font-style: normal;
      font-weight: 400;
      line-height: $font-height-simple-text;
    }

    .option-text-unavalaible {
      margin-bottom: 20px;
      color: #a7aaba;
      font-family: Inter, sans-serif;
      font-size: $font-size-simple-text;
      font-style: normal;
      font-weight: 400;
      line-height: $font-height-simple-text;
      text-decoration-line: line-through;
    }

    .offer-button {
      border: none;
      padding: 17px 45.55px 18.29px 45px;
      border-radius: 14px;
      background: #fff;
      box-shadow: 0px 16px 40px 0px rgba(62, 58, 58, 0.2);
      color: #1f1f1f;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }

    .offer-selected-button {
      border: none;
      padding: 17px 45.55px 18.29px 45px;
      border-radius: 14px;
      background: #ff7049;
      box-shadow: 0px 16px 40px 0px rgba(62, 58, 58, 0.2);
      color: #fff;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }

    .form-check-input:checked {
      background-color: #563bff;
      border-color: #563bff;
    }

    // .form-check-input:focus {
    //   box-shadow: 0 0 0 0.25rem rgba(255, 111, 71, .25);
    // }

    .credit-card {
      overflow: hidden;
      height: 225.049px;
      padding: 21.83px 28.06px 21.9px 28.79px;
      box-shadow: 0px 0px 1.1038646697998047px rgba(0, 0, 0, 0.04),
        0px 2.2077293395996094px 6.623188018798828px rgba(0, 0, 0, 0.04),
        0px 17.661834716796875px 26.492752075195312px rgba(0, 0, 0, 0.06);
      border-radius: 22px;
      background: linear-gradient(180deg, #197bbd 0%, #3391d0 100%);
    }

    .card-title {
      color: #fff;
      font-family: $font-family-primary;
      font-size: 18.487px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .card-account-type {
      color: rgba(255, 255, 255, 0.39);
      font-family: $font-family-primary;
      font-size: 8.956px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0;
    }

    .credit-card-digits {
      color: #fff;
      font-family: $font-family-primary;
      font-size: 21.724px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .info-title {
      color: #fff;
      font-family: $font-family-primary;
      font-size: 7.923px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .info {
      color: #fff;
      font-family: $font-family-primary;
      font-size: 12.927px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .add-credit-card {
      gap: 7px;
      color: #000;
      font-family: $font-family-primary;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      height: 225.049px;
      overflow: hidden;
      border: none;
      border-radius: 22px;
      background: #e5e5e5;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ellipse {
      position: absolute;
      border-radius: 276px;
      background: #dad9d9;
      width: 276px;
      height: 276px;
      flex-shrink: 0;
    }

    .payment-information {
      .action-btn {
        border-radius: 10px;
        background: #ff7049;
        padding: 12px 44px;
        box-shadow: 0px 16.20517px 37.81205px 0px rgba(255, 112, 73, 0.2);
        color: #fff;
        font-family: Roboto, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .form-label {
        color: #737791;
        font-family: $font-family-primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }

      .form-input {
        padding-bottom: 14px !important;
        padding-left: 0px !important;
        padding-top: 0 !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        background-color: transparent !important;
        border-bottom: solid 3px #e5e5e5 !important;
        color: #313131 !important;
        font-family: $font-family-primary !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 140% !important;
      }

      .form-check-input {
        width: 27px;
        height: 27px;
        border-radius: 8px;
        border: 1.5px solid #e3e8e7;
        background-color: #fff;

        &:checked {
          background-color: #05b714;
        }

        &:focus {
          box-shadow: 0 0 0 0.15rem rgba(5, 184, 20, 0.25);
        }
      }
    }

    .cell-header {
      height: 100%;
      padding-top: 21px;
      padding-bottom: 35px;
      color: #6b6f7b;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .cell-subtitle {
      color: #6b6f7b;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cell-title {
      color: #313a4e;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    div[role="cell"] {
      padding-top: 31px;
      padding-bottom: 36px;
    }

    .rdt_TableCell[data-column-id="1"] {
      padding-right: 5px !important;
    }

    .rdt_TableCell[data-column-id="8"] {
      justify-content: end;
    }

    .avatar-xs {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 48px;
      width: 49px;
      height: 49px;
      background: #f4f4f4;
      color: #cbcbcb;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      flex-shrink: 0 !important;
    }

    .form-check-input_invoice-table {
      margin: 5px !important;
      width: 20px;
      height: 20px;
      border-radius: 8px;
      border: 1.5px solid #e3e8e7;
      background-color: #fff;

      &:checked {
        background-color: #05b714;
        border-color: #05b714;
      }

      &:focus {
        box-shadow: 0 0 0 0.15rem rgba(5, 184, 20, 0.25);
      }
    }
  }
}

// Help
.help-page {
  .help__section_card {
    cursor: pointer;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);
  }
  .help__section_title {
    color: #091b3d;
    font-family: $font-family-tertiary;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .help__section_articles {
    color: #838383;
    font-size: 18px;
    font-family: $font-family-primary;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
  }
}

// Details
.details-page {
  .article-picture {
    border-radius: 38px;
  }
  .article-badge {
    font-family: $font-family-primary;
    padding: 7px;
    border-radius: 11.889px;
    background: #e8f7f7;
    color: #329993;
    font-style: normal;
    font-weight: 500;
  }
  .details-page-content {
    color: #000;
    font-family: $font-family-primary;
    font-size: $font-size-simple-text;
    font-style: normal;
    font-weight: 400;
    line-height: $font-height-simple-text;
    text-align: justify;
  }
  .details-link {
    color: #000;
    font-family: $font-family-primary;
    font-size: $font-size-simple-text;
    font-style: normal;
    font-weight: 400;
    line-height: $font-height-simple-text;
  }
}

// feedbackSection
.feebackSection {
  background: #f7f7f7;
  border-radius: 20px;
}
.feebackSection:hover {
  background: #e8e8e8;
}
.feedDescription {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}
.feedTitle {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.feedBack-switch {
  background: #f7f7f7;
  border-radius: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.feedBack-switch:hover {
  background: #e8e8e8;
}
.feedBack-switch-seclected {
  border: solid;
  border-color: #999;
  border-width: 1px;
}

//gift-anime

// Colors
$custom_blue: #563bff;
$white: #fff;

// Misc
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $custom_blue;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $custom_blue;
  fill: none;
  animation: stroke 0.6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $custom_blue;
  }
}
//gift-anime-end
// Ressources

.ressources-content {
  .action-btn {
    border-radius: 10px;
    background: #ff7049;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .action-btn-xl {
    color: #fff;
    padding: 10px 20px;
    font-family: $font-family-secondary;
    font-size: $font-size-simple-text;
    font-style: normal;
    font-weight: 500;
    line-height: $font-height-simple-text;
  }

  .action-btn-lg {
    color: #fff;
    text-align: center;
    font-family: $font-family-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    padding: 11px 31px 10px 32px;
  }
  .ressourcesList_item {
    margin-bottom: 30px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.1);
    padding-top: 37px;
    padding-right: 29px;
    padding-bottom: 37px;
    padding-left: 35px;
    // border-left: solid 5px #563BFF;
  }
  .form-check-input {
    width: 20px;
    height: 20px;
    border-radius: 6.756px;
    border: 1px solid #e3e8e7;
    background-color: #fff;

    &:checked {
      background-color: #05b714;
    }

    &:focus {
      box-shadow: 0 0 0 0.15rem rgba(5, 184, 20, 0.25);
    }
  }
  .ressource-name {
    color: #091b3d;
    font-family: $font-family-primary;
    font-size: $font-size-simple-text;
    font-style: normal;
    font-weight: 500;
    line-height: $font-height-simple-text;
  }
  .category-dropdown {
    border-radius: 10px;
    background: #f7f8fb;
    padding: 16px 10px 15px 20px;
  }
  .text-area {
    padding: 16px 21px;
    height: 76px;
    border-radius: 10px;
    background: #f7f8fb;
    resize: none;
    color: #666969;
    font-family: $font-family-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .pagination-next {
    padding: 8px 22px;
    border-radius: 8px;
    background: #ffa412;
    box-shadow: 0px 16.20517px 37.81205px 0px rgba(255, 164, 18, 0.2);
    color: #fffaf1;
    font-family: $font-family-primary;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .pagination-style {
    color: #666969;
    font-family: $font-family-primary;
    font-size: $font-size-simple-text;
    font-style: normal;
    font-weight: 400;
    line-height: $font-height-simple-text;
  }

  .currentPage {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 13.2px 0px rgba(0, 0, 0, 0.1);
    padding: 5px 34px 4px 34px;
    margin-left: 8px;
    margin-right: 10px;
  }
}

// Custom app mode switch
.custom_switch {
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cdd3eb;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: -2px;
    bottom: 4px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: 0 0px 15px #2020203d;
    background: white url("./../../../images/moon-stars.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60% 60%;
  }

  // input:checked+.slider {
  //   background-color: #2196f3;
  // }

  // input:focus+.slider {
  //   box-shadow: 0 0 1px #2196f3;
  // }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background: white url("./../../../images/sun-max.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60% 60%;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

// Calender

.calendar-content {
  .action-btn {
    width: fit-content;
    border: none !important;
    border-radius: 16.205px;
    background: #ff7049;
    padding: 14px 26px;
    box-shadow: 0px 16.20517px 37.81205px 0px rgba(255, 112, 73, 0.2);
    color: #fff;
    font-family: $font-family-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.introjs-helperLayer {
  box-shadow: rgba(16, 9, 54, 0.5) 0px 0px 0px 5000px !important;
}

.introjs-helperLayer {
  border-radius: 13.087px;
}

.introjs-arrow.left,
.introjs-arrow.bottom,
.introjs-arrow.right,
.introjs-arrow.top,
.introjs-arrow.left-bottom,
.introjs-arrow.right-bottom {
  border: none !important;
}

.introjs-tooltiptext {
  padding: 0 15px;
  color: #272b35;
  font-family: $font-family-primary;
  font-size: 16.248px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.introjs-tooltipbuttons {
  padding: 10px 0 17.9px 0;
  margin: 0 27.38px 0 15px;
}

.introjs-button {
  border-radius: 5.191px;
  background: #ffa412;
  box-shadow: 0px 10.5144px 24.53361px 0px rgba(255, 164, 18, 0.2);
  border: none;
  color: #fffaf1;
  font-family: $font-family-primary;
  font-size: 9.732px;
  font-style: normal;
  text-shadow: none;
  font-weight: 500;
  line-height: 15.572px;
  &:focus {
    color: #fffaf1;
    background: #ffa412;
    box-shadow: none;
    border: none;
  }
  &:active,
  &:hover {
    color: #fffaf1;
    background: #ffa412;
  }
}

.sidebar-menu-tooltip {
  border-radius: 23.212px 23.212px 23.212px 0px;
}

.top-menu-tooltip {
  border-radius: 23.212px 0px 23.212px 23.212px;
}

.view-tutorial-button-tooltip {
  border-radius: 0px 23.212px 23.212px 23.212px;
}

.area-one-tooltip {
  border-radius: 23.212px 23.212px 0px 23.212px;
}

.client-feedbacks {
  /* Pour la barre de défilement principale */
  ::-webkit-scrollbar {
    z-index: 999 !important;
    height: 3px !important;
  }
}

.tutorial-video-modal {
  max-width: 940px !important;

  .modal-content {
    width: fit-content;
  }

  .body-text {
    color: #272b35;
    font-family: $font-family-primary;
    font-size: $font-size-simple-text;
    font-style: normal;
    font-weight: 400;
    line-height: $font-height-simple-text;
  }

  .action-btn {
    border-radius: 8px;
    padding: 8px 27px;
    border: none;
    color: #fffaf1;
    font-family: $font-family-primary;
    font-size: $font-size-simple-text;
    font-style: normal;
    font-weight: 500;
    line-height: $font-height-simple-text;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .red-btn {
    background: rgba(241, 9, 68, 1);
    box-shadow: 0px 16.205px 37.812px 0px rgba(241, 9, 68, 0.2);
  }
  .gray-btn {
    background-color: #cdcdcd;
    color: #000;
  }
}

.user-modal {
  .card {
    background-color: inherit;
  }
}

.modal {
  background: rgba(255, 255, 255, 0.56);
  backdrop-filter: blur(4px);
  .modal_title {
    color: #000;
    text-align: center;
    font-family: $font-family-primary;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .close_button {
    color: black !important;
  }
  .modal-content {
    width: 100%;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 82.5px 0px rgba(0, 0, 0, 0.25);
  }
  .modal-header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .form-control {
    height: 55px;
    border-radius: 10px;
    background-color: #f2f4fb !important;
    border: none;
  }
  .select2-selection__control {
    height: 55px;
    border-radius: 10px;
    background-color: #f2f4fb !important;
    border: none;
    box-shadow: none !important;
    &:focus {
      border: none;
      box-shadow: none !important;
    }
  }
  .select2-selection__value-container {
    display: flex;
    height: 60%;
    overflow-y: auto;
    width: 63%;
    flex-wrap: initial;
  }
  .select2-selection__menu {
    z-index: 9999 !important;
  }
  .help-page-modal {
    max-width: 50% !important;

    .help-page-modal-button {
      width: 100%;
      padding: 26px 20px 26px 30px;
      border-radius: 20px;
      border: 1px solid #cdcdcd;
      background: #fff;
      color: #000 !important;
      font-family: $font-family-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .topics_modal__wrapper {
    max-width: 100% !important;
    margin: 0;
    .modal-content {
      height: 100vh;
    }
  }

  .billing-page-modal {
    .addOn_label {
      color: #000;
      font-family: $font-family-primary;
      font-size: $font-size-simple-text;
      font-style: normal;
      font-weight: 500;
      margin-right: 10px;
      line-height: $font-height-simple-text;
      text-transform: capitalize;
      display: flex;
      align-items: center;
    }
    .addOn_cost {
      color: #329993;
      text-align: right;
      font-family: Inter, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .form-check-input {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 1.5px solid #e3e8e7;
      box-shadow: 0px 4px 10.2px 0px rgba(0, 0, 0, 0.1);
      background-color: #fff;

      &:checked {
        background-color: #05b714;
      }

      &:focus {
        box-shadow: 0 0 0 0.15rem rgba(5, 184, 20, 0.25);
      }
    }
    .addOn-modal-button {
      border: none;
      padding: 15px 45.55px 15px 45px;
      border-radius: 14px;
      background: #ff7049;
      box-shadow: 0px 16px 40px 0px rgba(62, 58, 58, 0.2);
      color: #fff;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
  }
}

.pp_button {
  background-color: #cdcdcd !important;
  .inner_div {
    color: #cdcdcd !important;
  }
  border: none;
  padding: 3px;
  box-shadow: 0px 34px 194px 0px rgba(35, 38, 47, 0.19),
    30px 30px 50px -10px rgba(35, 38, 47, 0.17),
    14px 14px 64px -10px rgba(35, 38, 47, 0.25) inset,
    10px 10px 14px 0px rgba(35, 38, 47, 0.05) inset;
}
.pp_button_playing {
  background: linear-gradient(
    299deg,
    #8fff4a 6.09%,
    #77ec30 53.02%,
    #41b524 96.2%
  );
  .inner_div {
    color: #8fff4a !important;
  }
}
.pp_button_paused {
  background-color: red !important;
  box-shadow: none !important;
}
.inner_div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #fff;
}

.view-button {
  // width: fit-content;
  border: none;
  border-radius: 18px;
  color: #fff;
  // background-color: #ff7049;
  padding: 13.36px 10.93px 12.15px 12.15px;
  // box-shadow: 0px 19.683547973632812px 45.92827606201172px rgba(255, 112, 73, 0.2);
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 1.25rem;
  gap: 8px;
  min-width: 150px;
  // width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ressouceAdd-button {
  background-color: #ff7049;
}
.addAgent-button {
  background-color: rgb(86, 59, 255);
}

/* Pour la barre de défilement principale */
::-webkit-scrollbar {
  z-index: 999 !important;
  width: 3px !important; /* Largeur de la barre de défilement */
}

/* Pour la piste (le fond de la barre de défilement) */
::-webkit-scrollbar-track {
  z-index: 999 !important;
  background-color: #f1f1f1 !important; /* Couleur de fond de la piste */
}

/* Pour le pouce (la poignée de la barre de défilement) */
::-webkit-scrollbar-thumb {
  z-index: 999 !important;
  background-color: lightgray !important; /* Couleur du pouce */
  border-radius: 2px; /* Bord arrondi du pouce */
}

/* Pour le coin entre la piste et le pouce */
::-webkit-scrollbar-corner {
  background-color: #f1f1f1 !important; /* Couleur de fond du coin */
}

/* Lorsque la souris est sur la barre de défilement */
::-webkit-scrollbar-thumb:hover {
  background-color: gray !important; /* Couleur du pouce au survol */
}
